import Signup from './components/Signup';

export default function App() {
	return (
		<div className="background-image bg-cover">
			{/* NAV */}
			<div className="w-full mx-auto">
				<div className="w-full flex items-center justify-between">
					<a className="flex px-2 items-center text-indigo-100 no-underline hover:no-underline font-bold text-xl lg:text-2xl" href="/">
						Synth<span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-600 to-purple-600">Love</span>
					</a>

					<div className="flex w-1/2 justify-end content-center">
						{/* <a
							className="inline-block text-blue-300 no-underline hover:text-pink-500 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4"
							href="https://twitter.com/synthloveio"
							target="_blank"
							rel="noreferrer noopener"
						>
							<svg className="fill-current h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
								<path d="M30.063 7.313c-.813 1.125-1.75 2.125-2.875 2.938v.75c0 1.563-.188 3.125-.688 4.625a15.088 15.088 0 0 1-2.063 4.438c-.875 1.438-2 2.688-3.25 3.813a15.015 15.015 0 0 1-4.625 2.563c-1.813.688-3.75 1-5.75 1-3.25 0-6.188-.875-8.875-2.625.438.063.875.125 1.375.125 2.688 0 5.063-.875 7.188-2.5-1.25 0-2.375-.375-3.375-1.125s-1.688-1.688-2.063-2.875c.438.063.813.125 1.125.125.5 0 1-.063 1.5-.25-1.313-.25-2.438-.938-3.313-1.938a5.673 5.673 0 0 1-1.313-3.688v-.063c.813.438 1.688.688 2.625.688a5.228 5.228 0 0 1-1.875-2c-.5-.875-.688-1.813-.688-2.75 0-1.063.25-2.063.75-2.938 1.438 1.75 3.188 3.188 5.25 4.25s4.313 1.688 6.688 1.813a5.579 5.579 0 0 1 1.5-5.438c1.125-1.125 2.5-1.688 4.125-1.688s3.063.625 4.188 1.813a11.48 11.48 0 0 0 3.688-1.375c-.438 1.375-1.313 2.438-2.563 3.188 1.125-.125 2.188-.438 3.313-.875z"></path>
							</svg>
						</a> */}
					</div>
				</div>
			</div>

			{/* MAIN */}
			<div className="mx-auto items-center">
				<div style={{ height: '80vh' }} className="relative z-50">
					<div className="absolute left-1/2 -translate-x-2/4 bottom-0 w-5/6 sm:w-4/6 md:w-4/6 lg:w-2/4 xl:w-1/4 justify-center overflow-y-hidden mx-auto">
						<h1 className="py-2 pb-8 text-2xl md:text-4xl text-white leading-tight md:text-left">Fall in love with your own personalized AI companions</h1>
						<Signup />
					</div>
				</div>
				{/* <!--Footer--> */}
				<div className="w-full pt-16 pb-6 text-sm text-center md:text-left fade-in flex justify-center fixed bottom-0">
					<a className="text-gray-500 no-underline hover:no-underline text-xs" href="/">
						&copy; SynthLove 2023
					</a>
				</div>
			</div>
		</div>
	);
}
